import React, { Component } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';

import config from '../../config';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { isAnyFilterActive } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';

import { Footer, IconSpinner, Page } from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';

import MainPanelHeader from './MainPanelHeader/MainPanelHeader';

import css from './WishlistPage.module.css';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { addToWishlist } from './WishlistPage.duck';
import ShowFavSellers from './ShowFavSellers';
// import { addToWishList } from './WishlistPage.duck';
// SortBy component has its content in dropdown-popup.
// With this offset we move the dropdown a few pixels on desktop layout.

export class WishlistPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
    };

    this.searchMapListingsInProgress = false;

    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  // Apply the filters by redirecting to SearchPage with new filters.

  // Reset all filter query parameters

  render() {
    const {
      intl,
      listings,

      location,
      pagination,
      scrollingDisabled,
      wishlistListingsSearchProgress,
      wishlistListingsSearchError,
      likeListingInProgress,
      searchError,
      currentUser,
      onAddToWishList,
      favSellerWithProfileImage,
      favouriteSellerError,
      favouriteSellerLoading,
      sellerPagination,
      fetchWishlistInProgress,
      fetchWishlistError,
    } = this.props;
    const ensuredUser = ensureCurrentUser(currentUser);
    // eslint-disable-next-line no-unused-vars

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems = hasPaginationInfo ? pagination.totalItems : 0;
    const listingsAreLoaded = !wishlistListingsSearchProgress && hasPaginationInfo;

    const hasNoResult = listingsAreLoaded && totalItems === 0;
    const hasSearchParams = location.search?.length > 0;
    const noResultsInfo = hasNoResult ? (
      <div className={css.noSearchResults}>
        <FormattedMessage id="WishlistPage.noResults" />
        <br />
        {hasSearchParams ? (
          <button className={css.resetAllFiltersButton} onClick={e => this.resetAll(e)}>
            <FormattedMessage id={'WishlistPage.resetAllFilters'} />
          </button>
        ) : null}
      </div>
    ) : null;

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        // description={description}
        // title={title}
        title={'Wishlist'}

        // schema={schema}
      >
        <TopbarContainer className={topbarClasses} currentPage="WishlistPage" />
        <div className={css.layoutWrapperContainer}>
          <div className={css.layoutWrapperMain} role="main">
            <div className={css.searchResultContainer}>
              <div>
                <ShowFavSellers
                  favSellerWithProfileImage={favSellerWithProfileImage}
                  favouriteSellerError={favouriteSellerError}
                  favouriteSellerLoading={favouriteSellerLoading}
                  sellerPagination={sellerPagination}
                  currentUser={currentUser}
                />
              </div>
              <div
                className={classNames(css.listings, {
                  [css.newSearchInProgress]: !listingsAreLoaded,
                })}
              >
                {fetchWishlistInProgress ? (
                  <div className={css.loading}>
                    <div className={css.loader}>
                      <IconSpinner />
                    </div>
                  </div>
                ) : null}
                {fetchWishlistError ? (
                  <h2 className={css.error}>
                    <FormattedMessage id="WishlistPage.searchError" />
                  </h2>
                ) : null}
                {listings?.length === 0 ? (
                  <div className={css.noSearchResults}>
                    <FormattedMessage id="WishlistPage.WishlistPage.noListingInWishlist" />
                  </div>
                ) : null}
                <SearchResultsPanel
                  className={css.searchListingsPanel}
                  listings={listings}
                  likeListingInProgress={likeListingInProgress}
                  currentUser={currentUser}
                  pagination={pagination}
                  search={parse(location.search)}
                  onAddToWishList={onAddToWishList}
                  isMapVariant={false}
                  isWishlistPage={true}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Page>
    );
  }
}

WishlistPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.SearchRecordsFilters,
  sortConfig: config.custom.sortConfig,
};

WishlistPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool,
  // searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // const {
  //   currentPageResultIds,
  //   pagination,
  //   searchInProgress,
  //   searchListingsError,
  //   searchParams,
  // } = state.SearchPage;
  // const pageListings = getListingsById(state, currentPageResultIds);
  const {
    pagination,
    wishlistListingsSearchError,
    wishlistListingsSearchProgress,
    currentPageResultIds,
    likeListingInProgress,
    favouriteSeller,
    favouriteSellerError,
    favouriteSellerLoading,
    sellerPagination,
    fetchWishlistInProgress,
    fetchWishlistError,
  } = state.WishlistPage;

  const { currentUser } = state.user;
  const pageListings = getListingsById(state, currentPageResultIds);
  const favSellerWithProfileImage = favouriteSeller?.data?.map(seller => ({
    ...seller,
    profileImage: favouriteSeller?.included?.find(
      s => s?.id.uuid === seller?.relationships?.profileImage?.data?.id?.uuid
    ),
  }));
  return {
    listings: pageListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    wishlistListingsSearchError,
    wishlistListingsSearchProgress,
    likeListingInProgress,
    currentUser,
    favSellerWithProfileImage,
    favouriteSellerError,
    favouriteSellerLoading,
    sellerPagination,
    fetchWishlistInProgress,
    fetchWishlistError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onAddToWishList: listingId => dispatch(addToWishlist(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const WishlistPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WishlistPageComponent);

export default WishlistPage;
