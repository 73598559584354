import React, { Component } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import ReactStars from 'react-rating-stars-component';
import { ensureCurrentUser } from '../../util/data';

import config from '../../config';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';

import { propTypes } from '../../util/types';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';

import { AvatarLarge, Footer, IconSpinner, Page, PaginationLinks } from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';

import css from './WishlistPage.module.css';

import { addSellerToFavourites } from '../ProfilePage/ProfilePage.duck';
import { parse } from '../../util/urlHelpers';
// import { addToWishList } from './WishlistPage.duck';
// SortBy component has its content in dropdown-popup.
// With this offset we move the dropdown a few pixels on desktop layout.

export class SellerFavouritePageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      removeId: null,
    };
  }
  render() {
    const {
      pagination,
      scrollingDisabled,
      currentUser,
      favSellerWithProfileImage,
      favouritiesLoading,
      favouritiesError,
      onAddSellerToFavourites,
      addSellerToFavouritesInProgress,
      location,
    } = this.props;

    if (!currentUser) return null;
    const { attributes } = currentUser;
    const { profile } = attributes;
    const { protectedData } = profile;
    const { favourites = [] } = protectedData;
    // if (!favourites?.length) return null;
    const search = parse(location.search);
    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems = hasPaginationInfo ? pagination.totalItems : 0;
    const paginationLinks =
      pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName={'SellerFavouritePage'}
          pageSearchParams={search}
          pagination={pagination}
        />
      ) : null;
    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    return (
      <Page scrollingDisabled={scrollingDisabled} title={'Favorites'}>
        <TopbarContainer className={css.topbar} currentPage="FavoritePage" />
        <div className={css.layoutWrapperContainer}>
          <div className={css.layoutWrapperMain} role="main">
            <div className={css.content}>
              {favSellerWithProfileImage?.length ? (
                <h3>
                  <FormattedMessage
                    id="WishlistPage.SellerFavouritePage.sellerFollowLabel"
                    values={{ fav: favourites?.length }}
                  />
                </h3>
              ) : (
                <h3>
                  {' '}
                  <FormattedMessage id="WishlistPage.ShowFavSellers.nosellerLabel" />
                </h3>
              )}
              {favouritiesLoading ? (
                <div className={css.loading}>
                  <div className={css.loader}>
                    <IconSpinner />
                  </div>
                </div>
              ) : null}
              {/* {favouritiesError ? (
                <div className={css.error}>Something went wrong. Please refresh the page.</div>
              ) : null} */}
              <div className={css.sellerContainerSellerPage}>
                {favSellerWithProfileImage && favSellerWithProfileImage.length > 0
                  ? favSellerWithProfileImage.map((seller, index) => {
                      const authorsMetadata = seller?.attributes?.profile?.metadata;
                      const { avgRating, rating, totalReviews } = authorsMetadata ?? {};
                      const ratings = {
                        size: 14,
                        value: avgRating ? avgRating : rating,
                        edit: false,
                        isHalf: true,
                      };
                      return (
                        <div className={css.seller}>
                          <AvatarLarge
                            key={index}
                            className="Avatar__large"
                            user={seller}
                            avatarClassName="Avatar__image"
                          />
                          <div className={css.sellerName}>
                            {seller.attributes.profile.displayName}
                          </div>
                          {/* {(avgRating || rating) && ( */}
                          <div className={css.reviewContainer}>
                            <div className={css.rating}>
                              <ReactStars activeColor="#b9575a" {...ratings} />
                            </div>
                            <div className={css.totalreviews}>
                              ({totalReviews ? totalReviews : '0'})
                            </div>{' '}
                          </div>
                          {/* )} */}
                          <div
                            className={css.delete}
                            onClick={() => {
                              this.setState({ removeId: seller.id.uuid });
                              onAddSellerToFavourites(seller.id.uuid);
                            }}
                          >
                            {addSellerToFavouritesInProgress &&
                            this.state.removeId === seller.id.uuid ? (
                              <IconSpinner />
                            ) : (
                              <FormattedMessage id="WishlistPage.SellerFavouritePage.deleteFromFavLabel" />
                            )}
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
        {paginationLinks}
        <Footer />
      </Page>
    );
  }
}

SellerFavouritePageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.SearchRecordsFilters,
  sortConfig: config.custom.sortConfig,
};

SellerFavouritePageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool,
  // searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    favouritiesLoading,
    favouritiesError,
    favourities = [],
    pagination,
  } = state.SellerFavouritePage;

  const { addSellerToFavouritesInProgress } = state.ProfilePage;
  const { currentUser } = state.user;
  const favSellerWithProfileImage = favourities?.data?.map(seller => ({
    ...seller,
    profileImage: favourities?.included?.find(
      s => s?.id.uuid === seller?.relationships?.profileImage?.data?.id?.uuid
    ),
  }));
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    favSellerWithProfileImage,
    favouritiesLoading,
    favouritiesError,
    pagination,
    addSellerToFavouritesInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onAddSellerToFavourites: sellerId => dispatch(addSellerToFavourites(sellerId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SellerFavouritePage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SellerFavouritePageComponent);

export default SellerFavouritePage;
