import React from 'react';
import { Avatar, AvatarLarge, IconSpinner, NamedLink } from '../../components';
import css from './WishlistPage.module.css';
import ReactStars from 'react-rating-stars-component';
import { FormattedMessage } from 'react-intl';
const ShowFavSellers = props => {
  const {
    favSellerWithProfileImage,
    favouriteSellerError,
    favouriteSellerLoading,
    sellerPagination,
    currentUser,
  } = props;
  if (!currentUser) return null;
  const { attributes } = currentUser;
  const { profile } = attributes;
  const { protectedData } = profile;
  const { favourites = [] } = protectedData;
  // if (!favourites?.length) return null;
  return (
    <div className={css.sellerRoot}>
      {favourites?.length ? (
        <div className={css.viewAllContainer}>
          <h3>
            <FormattedMessage
              id="WishlistPage.SellerFavouritePage.sellerFollowLabel"
              values={{ fav: favourites?.length }}
            />
          </h3>
          <NamedLink name="SellerFavouritePage" className={css.viewAll}>
            <FormattedMessage id="WishlistPage.ShowFavSellers.seeAllLabel" />
          </NamedLink>
        </div>
      ) : (
        <div className={css.viewAllContainer}>
          <h3>
            <FormattedMessage id="WishlistPage.ShowFavSellers.nosellerLabel" />
          </h3>
        </div>
      )}
      {favouriteSellerLoading ? (
        <div className={css.loading}>
          <div className={css.loader}>
            <IconSpinner />
          </div>
        </div>
      ) : null}
      {/* {favouriteSellerError ? (
        <div className={css.error}>Something went wrong. Please refresh the page.</div>
      ) : null} */}
      <div className={css.sellerContainer}>
        {favSellerWithProfileImage && favSellerWithProfileImage.length > 0 && favourites?.length
          ? favSellerWithProfileImage.map((seller, index) => {
              const authorsMetadata = seller?.attributes?.profile?.metadata;
              const { avgRating, rating, totalReviews } = authorsMetadata ?? {};
              const ratings = {
                size: 14,
                value: avgRating ? avgRating : rating,
                edit: false,
                isHalf: true,
              };
              return (
                <div className={css.seller}>
                  <AvatarLarge
                    key={index}
                    className="Avatar__large"
                    user={seller}
                    avatarClassName="Avatar__image"
                  />
                  <div className={css.sellerName}>{seller.attributes.profile.displayName}</div>
                  {(avgRating || rating) && (
                    <div className={css.reviewContainer}>
                      <div className={css.rating}>
                        <ReactStars activeColor="#b9575a" {...ratings} />
                      </div>
                      <div className={css.totalreviews}>({totalReviews ? totalReviews : '0'})</div>{' '}
                    </div>
                  )}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default ShowFavSellers;
